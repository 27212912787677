import React from 'react'

const inline={
    textAlign: 'center',
    marginBottom: 0,
    paddingBottom: '2rem'
}

const Footer = () =>{
    return(
        <footer>
            <div className="conteiner">
                <p style={inline}>© {new Date().getFullYear()}, SAKI HAYASHI All Rights Reserved.</p>
            </div>
        </footer>
    )
}

export default Footer