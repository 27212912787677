import React from 'react'
import Image from 'gatsby-image'
import { StaticQuery, graphql, Link } from 'gatsby'
import './header.scss'

const Header = () =>{
    return(
        <StaticQuery
            query={headerQuery}
            render={data => {
                const { author, social } = data.site.siteMetadata
                console.log('query check', data)
                return(
                    <header>
                      <Link to="/">
                        <Image
                          fixed={data.avatar.childImageSharp.fixed}
                          alt={author}
                          />
                          <p className="subtitle">
                              3D/2D UX Designer / Full-stack Web Developer
                          </p>
                      </Link>
                        
                    </header>
                )
                }}
        />
    )
}

const headerQuery = graphql`
  query HeaderQuery {
    avatar: file(absolutePath: { regex: "/logo.png/" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`

export default Header